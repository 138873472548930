import { cn } from '@/lib/utils';

type THeroCardsProps = {
  className?: string;
};
export function HeroCards({ className }: Readonly<THeroCardsProps>) {
  return (
    <div className={cn('w-full', className)}>
      <div className="container">
        <div className="grid gap-10 md:grid-cols-2 md:gap-x-4 lg:grid-cols-4">
          <div className="relative rounded-lg border border-border-default bg-bg-body p-6 pt-10">
            <IconMyAccount className="-top-10 absolute left-0" />
            <p className="subtitle2-bold">My account</p>
            <p className="body1-regular text-fg-weak">Edit and manage all your account details.</p>
          </div>

          <div className="relative rounded-lg border border-border-default bg-bg-body p-6 pt-10">
            <IconCustomerService className="-top-10 absolute left-0" />
            <p className="subtitle2-bold">Customer service</p>
            <p className="body1-regular text-fg-weak">
              Reach out to the right team for fast, friendly support.
            </p>
          </div>

          <div className="relative rounded-lg border border-border-default bg-bg-body p-6 pt-10">
            <IconSmartGift className="-top-10 absolute left-0" />
            <p className="subtitle2-bold">You send, they choose</p>
            <p className="body1-regular text-fg-weak">
              Send gifts via email, and let recipients choose their favorite.
            </p>
          </div>

          <div className="relative rounded-lg border border-border-default bg-bg-body p-6 pt-10">
            <IconBulkGiftManager className="-top-10 absolute left-0" />
            <p className="subtitle2-bold">Bulk gift manager</p>
            <p className="body1-regular text-fg-weak">
              Gifting to many? Simply upload your spreadsheet to send.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

type THeroCardIconProps = Readonly<{
  className?: string;
}>;
function IconMyAccount({ className }: THeroCardIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={104}
      height={104}
      fill="none"
      aria-labelledby="icon-my-account"
      className={className}
    >
      <title id="icon-my-account">Icon My Account</title>
      <g filter="url(#a)">
        <path
          fill="url(#b)"
          d="M20 42c0-17.673 14.327-32 32-32s32 14.327 32 32-14.327 32-32 32-32-14.327-32-32"
          shapeRendering="crispEdges"
        />
        <path
          stroke="#CBF4FE"
          d="M52 73.5c-17.397 0-31.5-14.103-31.5-31.5S34.603 10.5 52 10.5 83.5 24.603 83.5 42 69.397 73.5 52 73.5Z"
          shapeRendering="crispEdges"
        />
        <path
          fill="#8DC8FF"
          d="M42.7 33.566c0-4.634 3.836-8.404 8.55-8.404s8.55 3.77 8.55 8.404-3.836 8.404-8.55 8.404-8.55-3.77-8.55-8.404m13.95 11.058h-10.8c-5.706 0-10.35 4.564-10.35 10.173 0 2.684 2.22 4.865 4.95 4.865h21.6c2.73 0 4.95-2.181 4.95-4.865 0-5.609-4.644-10.173-10.35-10.173"
        />
      </g>
      <defs>
        <radialGradient
          id="b"
          cx={0}
          cy={0}
          r={1}
          gradientTransform="rotate(90 5 47)scale(37)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#EDFCFF" />
        </radialGradient>
        <filter
          id="a"
          width={104}
          height={104}
          x={0}
          y={0}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={10} />
          <feGaussianBlur stdDeviation={10} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.616243 0 0 0 0 0.927397 0 0 0 0 1 0 0 0 0.3 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_947_2280" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_947_2280" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={1} />
          <feGaussianBlur stdDeviation={0.5} />
          <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0.415081 0 0 0 0 0.818209 0 0 0 0 0.912272 0 0 0 0.8 0" />
          <feBlend in2="shape" result="effect2_innerShadow_947_2280" />
        </filter>
      </defs>
    </svg>
  );
}

function IconCustomerService({ className }: THeroCardIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={104}
      height={104}
      fill="none"
      className={className}
      aria-labelledby="icon-my-account"
    >
      <title id="icon-my-account">Icon My Account</title>
      <g filter="url(#a)">
        <path
          fill="url(#b)"
          d="M20 42c0-17.673 14.327-32 32-32s32 14.327 32 32-14.327 32-32 32-32-14.327-32-32"
          shapeRendering="crispEdges"
        />
        <path
          stroke="#E1D8FF"
          d="M52 73.5c-17.397 0-31.5-14.103-31.5-31.5S34.603 10.5 52 10.5 83.5 24.603 83.5 42 69.397 73.5 52 73.5Z"
          shapeRendering="crispEdges"
        />
        <path
          fill="#BFAAFF"
          d="M61.736 56.067H41.484c-5.058 0-7.826-5.895-4.596-9.787l.18-.217a9.55 9.55 0 0 0 2.202-6.101v-3.906c0-6.845 5.55-12.394 12.395-12.394s12.394 5.55 12.394 12.395v4.037c0 2.252.795 4.43 2.246 6.153 3.27 3.885.51 9.82-4.569 9.82"
        />
        <path
          fill="#fff"
          d="M41.681 60.123c1.62 1.619 6.096.88 10.203.809 3.853-.067 9.334.65 10.237-1.155s.312-2.546-.33-3.188c-.643-.643-2.926-1.208-2.926-1.208a4.69 4.69 0 0 1-2.844-4.313v-.822H47.2v.867a4.69 4.69 0 0 1-2.821 4.303s-2.121.596-2.698 1.173c-.576.576-1.619 1.915 0 3.534"
        />
        <path
          fill="#fff"
          d="M60.427 36.977v7.297a7.444 7.444 0 0 1-7.444 7.444h-2.745a7.444 7.444 0 0 1-7.444-7.444v-7.297a4.84 4.84 0 0 1 4.843-4.842h7.947a4.84 4.84 0 0 1 4.843 4.842"
        />
        <path
          fill="#715BB5"
          d="m60.426 40.773-.01.03c-.27.93-.975 2.685-2.682 4.001a7.5 7.5 0 0 1-2.694 1.316.843.843 0 1 0 .428 1.63 9.2 9.2 0 0 0 3.295-1.611 9 9 0 0 0 1.659-1.669q.003-.097.005-.196z"
        />
        <path
          fill="#715BB5"
          d="M54.116 48.45h-1.974a1.627 1.627 0 1 1 0-3.255h1.974a1.627 1.627 0 0 1 0 3.255"
        />
        <path
          fill="#BFAAFF"
          d="M51.665 23.662c2.799 0 5.381.928 7.456 2.493-1.035 1.801-3.412 5.362-7.869 7.999a21.9 21.9 0 0 1-8.349 2.862H39.27v-.96c0-6.845 5.55-12.394 12.395-12.394"
        />
      </g>
      <defs>
        <radialGradient
          id="b"
          cx={0}
          cy={0}
          r={1}
          gradientTransform="rotate(90 5 47)scale(40.5)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#F5F2FF" />
        </radialGradient>
        <filter
          id="a"
          width={104}
          height={104}
          x={0}
          y={0}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={10} />
          <feGaussianBlur stdDeviation={10} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.74902 0 0 0 0 0.666667 0 0 0 0 1 0 0 0 0.2 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_986_2358" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_986_2358" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={1} />
          <feGaussianBlur stdDeviation={0.5} />
          <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0.520211 0 0 0 0 0.392052 0 0 0 0 0.910791 0 0 0 0.8 0" />
          <feBlend in2="shape" result="effect2_innerShadow_986_2358" />
        </filter>
      </defs>
    </svg>
  );
}

function IconSmartGift({ className }: THeroCardIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={104}
      height={104}
      fill="none"
      className={className}
      aria-labelledby="icon-smart-gift"
    >
      <title id="icon-smart-gift">Icon SmartGift</title>
      <g filter="url(#a)">
        <path
          fill="url(#b)"
          d="M20 42c0-17.673 14.327-32 32-32s32 14.327 32 32-14.327 32-32 32-32-14.327-32-32"
          shapeRendering="crispEdges"
        />
        <path
          stroke="#CDE8F8"
          d="M52 73.5c-17.397 0-31.5-14.103-31.5-31.5S34.603 10.5 52 10.5 83.5 24.603 83.5 42 69.397 73.5 52 73.5Z"
          shapeRendering="crispEdges"
        />
        <path
          fill="#253FA6"
          fillRule="evenodd"
          d="M66.544 46.193c-.18.298-4.23 7.04-5.09 8.828-.88 1.828-3.207 1.635-3.207 1.635l-15.625-.028 1.048-4.664-3.782-4.751h23.52c.678 0 1.2.029 1.754-.066.857-.142 1.316-.85 1.381-.955zM46.796 26.668l15.624.029-1.049 4.663 3.782 4.751H41.636c-.679 0-1.2-.029-1.756.066-.857.142-1.315.85-1.38.955.179-.296 4.228-7.04 5.089-8.829.88-1.826 3.208-1.635 3.208-1.635M41.28 37.146h13.223s3.658 2.255 7.953 4.762c1.72 1.003 2.807 1.705 2.849 2.584.043.884-.753 1.686-2.018 1.686H50.064s-2.207-1.39-7.952-4.762c-1.717-1.007-2.77-1.6-2.816-2.584-.043-.885.72-1.687 1.985-1.687"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <radialGradient
          id="b"
          cx={0}
          cy={0}
          r={1}
          gradientTransform="rotate(90 5 47)scale(41)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#E9F7FF" />
        </radialGradient>
        <filter
          id="a"
          width={104}
          height={104}
          x={0}
          y={0}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={10} />
          <feGaussianBlur stdDeviation={10} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.330388 0 0 0 0 0.688078 0 0 0 0 0.943571 0 0 0 0.2 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_986_2385" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_986_2385" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={1} />
          <feGaussianBlur stdDeviation={0.5} />
          <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0.0950212 0 0 0 0 0.193808 0 0 0 0 0.585156 0 0 0 0.3 0" />
          <feBlend in2="shape" result="effect2_innerShadow_986_2385" />
        </filter>
      </defs>
    </svg>
  );
}

function IconBulkGiftManager({ className }: THeroCardIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={104}
      height={104}
      fill="none"
      className={className}
      aria-labelledby="icon-bulk-gift-manager"
    >
      <title id="icon-bulk-gift-manager">Icon Bulk Gift Manager</title>
      <g filter="url(#a)">
        <path
          fill="url(#b)"
          d="M20 42c0-17.673 14.327-32 32-32s32 14.327 32 32-14.327 32-32 32-32-14.327-32-32"
          shapeRendering="crispEdges"
        />
        <path
          stroke="#BFEED4"
          d="M52 73.5c-17.397 0-31.5-14.103-31.5-31.5S34.603 10.5 52 10.5 83.5 24.603 83.5 42 69.397 73.5 52 73.5Z"
          shapeRendering="crispEdges"
        />
        <path
          fill="#fff"
          d="M64.284 23.662h-25.22c-1.14 0-2.064.924-2.064 2.064v32.786c0 1.14.924 2.064 2.064 2.064h25.22c1.14 0 2.064-.924 2.064-2.065V25.727c0-1.14-.924-2.064-2.064-2.064"
        />
        <path
          fill="#32DAB5"
          d="M62.766 26.846h-18.24v4.915h18.24c.22 0 .398-.178.398-.398v-4.12a.4.4 0 0 0-.398-.397"
        />
        <path
          fill="#32DAB5"
          d="M40.184 31.363v-4.12c0-.22.178-.397.398-.397h4.02v4.915h-4.02a.4.4 0 0 1-.398-.398"
        />
        <path
          fill="#B6C4CF"
          d="M51.361 51.157H40.64c-.353 0-.639-.26-.639-.58s.286-.579.639-.579H51.36c.353 0 .639.26.639.58s-.286.579-.639.579M51.361 55.538H40.64c-.353 0-.639-.26-.639-.58s.286-.58.639-.58H51.36c.353 0 .639.26.639.58s-.286.58-.639.58M61.698 37.162H40.802c-.443 0-.802-.336-.802-.75s.36-.75.802-.75h20.896c.443 0 .802.336.802.75s-.36.75-.802.75M61.698 41.662H40.802c-.443 0-.802-.336-.802-.75s.36-.75.802-.75h20.896c.443 0 .802.336.802.75s-.36.75-.802.75M52.66 46.162H40.84c-.464 0-.84-.336-.84-.75s.376-.75.84-.75h11.82c.464 0 .84.336.84.75s-.376.75-.84.75"
        />
        <path fill="#32DAB5" d="M62.5 61.162a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15" />
        <path
          fill="#fff"
          d="M61.804 56.966c-.3 0-.6-.115-.829-.344L57.9 53.547a.39.39 0 0 1 0-.551l1.107-1.108a.39.39 0 0 1 .551 0l2.246 2.246 3.638-3.637a.39.39 0 0 1 .55 0l1.108 1.108a.39.39 0 0 1 0 .55l-4.466 4.467c-.23.23-.53.344-.83.344"
        />
      </g>
      <defs>
        <radialGradient
          id="b"
          cx={0}
          cy={0}
          r={1}
          gradientTransform="rotate(90 5 47)scale(44)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#E5F8EE" />
        </radialGradient>
        <filter
          id="a"
          width={104}
          height={104}
          x={0}
          y={0}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={10} />
          <feGaussianBlur stdDeviation={10} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.196078 0 0 0 0 0.854902 0 0 0 0 0.709804 0 0 0 0.2 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_986_2393" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_986_2393" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={1} />
          <feGaussianBlur stdDeviation={0.5} />
          <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0.133247 0 0 0 0 0.6993 0 0 0 0 0.401378 0 0 0 0.8 0" />
          <feBlend in2="shape" result="effect2_innerShadow_986_2393" />
        </filter>
      </defs>
    </svg>
  );
}
