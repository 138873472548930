import Image from 'next/image';

import { Button, type ButtonProps } from '@/components/atoms';
import { Z_INDEX } from '@/constants';
import { cn } from '@/lib/utils';
import Link from 'next/link';
import { twMerge } from 'tailwind-merge';
import { HomeAnimation } from '../molecules/HomeAnimation';

type TCtaButton = ButtonProps & {
  url: string;
};
type HeroBannerProps = {
  heroTitle: string;
  heroSubtitle?: string;
  hasSecondaryButton?: boolean;
  imageUrl?: string;
  imageWrapperClassName?: string;
  videoUrl?: string;
  minHeight?: string;
  primaryButton?: TCtaButton;
  secondaryButton?: TCtaButton;
  backgroundColor?: string;
  className?: string;
  customContent?: React.ReactNode;
};

function HeroBanner({
  heroTitle,
  heroSubtitle,
  imageUrl,
  imageWrapperClassName,
  videoUrl,
  minHeight = '',
  primaryButton,
  secondaryButton,
  className,
  customContent,
}: Readonly<HeroBannerProps>) {
  return (
    <div className="w-full bg-bg-default">
      <div className="container relative">
        <div
          className={twMerge(
            `flex w-full flex-col justify-between gap-10 overflow-hidden lg:flex-row ${minHeight} lg:items-center ${Z_INDEX.standard1}`,
            className
          )}
          style={{ minHeight: videoUrl ? 'unset' : 342 }}
        >
          <div
            className={`order-2 flex h-full max-w-full flex-col justify-center gap-6 sm:gap-6 lg:order-1 lg:min-w-[440px] lg:max-w-[440px] ${Z_INDEX.standard2}`}
          >
            <div>
              <h2 className="display-small text-balance">{heroTitle}</h2>
              {heroSubtitle && <h3 className="subtitle1-regular mt-4 lg:mt-6">{heroSubtitle}</h3>}
            </div>

            {customContent && <div className="block lg:hidden">{customContent}</div>}

            {(primaryButton || secondaryButton) && (
              <div className="flex flex-col items-center gap-4 sm:flex-row">
                {primaryButton && (
                  <Button
                    href={primaryButton.url}
                    as={'a'}
                    color="secondary"
                    className={cn('w-full', !secondaryButton && 'sm:w-fit')}
                    size="lg"
                    aria-label={
                      typeof primaryButton.children === 'string' ? primaryButton.children : ''
                    }
                    {...primaryButton}
                  >
                    {primaryButton.children}
                  </Button>
                )}
                {secondaryButton && (
                  <Button
                    as={Link}
                    href={secondaryButton.url}
                    color="secondary"
                    className={cn('w-full', !primaryButton && 'sm:w-fit')}
                    variant="outlined"
                    size="lg"
                    aria-label={
                      typeof secondaryButton.children === 'string' ? secondaryButton.children : ''
                    }
                    {...secondaryButton}
                  >
                    {secondaryButton.children}
                  </Button>
                )}
              </div>
            )}
          </div>

          {customContent ? (
            <div className="order-1 hidden flex-grow overflow-hidden lg:order-1 lg:flex lg:flex-col">
              {customContent}
            </div>
          ) : (
            <>
              {imageUrl && (
                <div className={cn('order-1 h-full flex-grow lg:order-2', imageWrapperClassName)}>
                  <Image
                    src={imageUrl}
                    alt="Hero Banner"
                    width={1320}
                    height={492}
                    priority
                    className="h-auto min-h-[8rem] w-full flex-grow rounded-[1.5rem] object-cover object-top lg:h-[246px]"
                  />
                </div>
              )}

              {videoUrl && (
                <HomeAnimation
                  className={`${Z_INDEX.standard1} order-1 h-full w-full flex-1 object-right md:top-[10%] md:h-[80%] lg:order-2 xl:h-full`}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export { HeroBanner };
