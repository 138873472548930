import { ENV } from '@/config/env';
import { createApi } from '@/lib';

export const api = createApi({
  baseURL: 'https://cdn.contentstack.io/v3/content_types',
  defaultHeaders: {
    'Content-Type': 'application/json',
    api_key: ENV.CONTENTSTACK_API_KEY as string,
    access_token: ENV.CONTENTSTACK_DELIVERY_TOKEN as string,
  },
  defaultParams: {
    environment: ENV.CONTENTSTACK_ENVIRONMENT,
  },
});
