import { api } from './api';
import { endpoints } from './endpoints';

const getCSEntries = async <TResponse>(
  entryCode: string,
  params?: Record<string, string | Record<string, string>>
) => {
  try {
    const response = await api.get<TResponse>(endpoints.getCSEntries(entryCode), {
      params,
    });
    return response;
  } catch (error) {
    throw new Error(error as string);
  }
};

export { getCSEntries };
