import { useTranslations } from 'next-intl';
import Link from 'next/link';

import {
  Button,
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@/components/atoms';
import { ProductCard } from '@/components/molecules/ProductCard';
import { cn } from '@/lib/utils';
import { searchProducts } from '@/services/pwa/products/searchProducts';
import { getLink } from '@/utils/getLink';

export async function BusinessGiftingBestSellers() {
  const t = useTranslations();

  const responseSearchProducts = await searchProducts({
    body: { filters: { categoryIds: ['7802'] } },
  });

  return (
    <div className="w-full bg-bg-body">
      <div className="px-4 py-10 lg:px-[100px]">
        <h2 className="h1 mb-6 text-center sm:mb-10">Business Gifting Best Sellers</h2>
        <Carousel className="w-full max-w-full" hasDots>
          <CarouselContent className="-ml-6 sm:-ml-6">
            {responseSearchProducts?.docs.map((product) => (
              <CarouselItem
                key={product.internalProductId}
                className={cn(
                  'w-[272px] min-w-[272px] basis-auto px-0 pl-6 sm:w-auto sm:basis-1/3 md:basis-1/4'
                )}
              >
                <ProductCard product={product} />
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselPrevious className="top-[27%] hidden lg:flex" size="lg" />
          <CarouselNext className="top-[27%] hidden lg:flex" size="lg" />
        </Carousel>

        <div className="mt-6 flex w-full items-center justify-center">
          <Button
            href={getLink('signUp')}
            as={Link}
            prefetch={false}
            shallow={true}
            className="mx-auto w-full sm:w-fit"
            size="lg"
            aria-label={t('createAFreeAccount')}
          >
            {t('shopNow')}
          </Button>
        </div>
      </div>
    </div>
  );
}
